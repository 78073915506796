import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 class="rainbow">Steven Coll</h1>
        {/* <h2>CloudOps Engineer</h2> */}
        <nav>
          {/* <ul>
            <li><a href="#about">About</a></li>
            <li><a href="#projects">Projects</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul> */}
        </nav>
      </header>
      <main>
        {/* <section id="about">
          <h3>About Me</h3>
          <p>Hi, I'm Steven!</p>
        </section> */}
        {/* <section id="projects">
          <h3>Projects</h3>
          <ul>
            <li>
              <h4>Project 1</h4>
              <p>Description of project 1 goes here</p>
            </li>
            <li>
              <h4>Project 2</h4>
              <p>Description of project 2 goes here</p>
            </li>
            <li>
              <h4>Project 3</h4>
              <p>Description of project 3 goes here</p>
            </li>
          </ul>
        </section> */}
      </main>
      <footer>
        <p>&copy; 2023 Steven Coll</p>
      </footer>
    </div>
  );
}

export default App;